import React, { useContext, useEffect, useState } from 'react'
import {LanguageContext} from '../App'

const Courses = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  const {language} = useContext(LanguageContext)
  const [data,setData] = useState({
    "en": {
      "title": "Our Courses",
      "description": "We are proving these courses where students can make a good career",
      "fitter": "Fitter",
      "fu": "Units : 3 (2+1)",
      "admf": "Admission start : March 2023",
      "admp": "Fees : ₹ 36000 (18000 + 18000)",
      "electrician": "Electrician",
      "electricianu": "Units : 3 (2+1)",
      "electricianadm": "Admission start : March 2023",
      "electricianf": "Fees : ₹ 36000 (18000 + 18000)"
    },
    "hi": {
      "title": "हमारे कोर्सेज",
      "description": "हम उन कोर्सेज को प्रदान कर रहे हैं जहां छात्र एक अच्छे करियर का निर्माण कर सकते हैं",
      "fitter": "फिटर",
      "fu": "यूनिट्स: 3 (2+1)",
      "admf": "प्रवेश शुरू: मार्च 2023",
      "admp": "फीस: ₹ 36000 (18000 + 18000)",
      "electrician": "इलेक्ट्रीशियन",
      "electricianu": "यूनिट्स: 3 (2+1)",
      "electricianadm": "प्रवेश शुरू: मार्च 2023",
      "electricianf": "फीस: ₹ 36000 (18000 + 18000)"
    }
  })
  return (
    <div className="w3-services py-5">
    <div className="container py-lg-4">
      <div className="title-content text-left mb-lg-5 mb-4">
        <h6 className="sub-title" id="title">{data[language].title}</h6>
        <p id="description">{data[language].description}</p>

      </div>
      <div className="row w3-services-grids">
        <div className="col-md-6 causes-grid">
          <div className="causes-grid-info">
            <a><img src="assets/images/cf.jpg" className="img-fuild" alt=""/></a>
            <a className="cause-title-wrap">
              <h4 className="cause-title" id="fitter">{data[language].fitter}
              </h4>
            </a>
            <div className="row">
              <div className="col-md">
                <p className="card-text mb-0" id="fu">{data[language].fu}
                </p>
              </div>
              <div className="col-md">
                <p className="card-text mb-0" id="admf">{data[language].admf}
                </p>
              </div>
            </div>

            <h4 className="cause-title" id="admp">{data[language].admp}
            </h4>
          </div>
        </div>
        <div className="col-md-6 causes-grid">
          <div className="causes-grid-info">
            <a><img src="assets/images/ce.jpg" className="img-fuild" alt=""/></a>
            <a className="cause-title-wrap">
              <h4 className="cause-title" id="electrician">{data[language].electrician}
              </h4>
            </a>
            <div className="row">
              <div className="col-md">
                <p className="card-text mb-0" id="electricianu">{data[language].electricianu}
                </p>
              </div>
              <div className="col-md">
                <p className="card-text mb-0" id="electricianadm">{data[language].electricianadm}
                </p>
              </div>
            </div>

            <h4 className="cause-title" id="electricianf">{data[language].electricianf}
            </h4>
          </div>
        </div>

      </div>
    </div>
  </div>
  )
}

export default Courses
import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../App'
import Testimonials from './Testimonials'

const Home = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  const {language} = useContext(LanguageContext)
  const [data,setData] = useState({
    "en": {
      "admiopen" : "Admission Open",
      "readmore": "Read More",
      "whoareare": "Who we are",
      "werhere": "We Are Here From 11 Years",
      "werherecontent": "RSLALPITI (Late R.S. Lal Industrial Training Institute) is working under a widely known society of the district. The society is actively involved in various educational events and functions across the district and bringing the district to a countable state. The society is proudly works for the youth and help them make their future as well as the nation great.",
      "ychoose": "WHY CHOOSE US",
      "euneed": "Education You Need",
      "promise": "Promise to make you successful",
      "choose1": "Quality Education",
      "choose1para": "We have high quality education standards",
      "choose2": "Great Placement",
      "choose2para": "We will provide you the support for the placements",
      "choose3": "Industrial Visits",
      "choose3para": "We organize industrial visit to give industrial experience.",
      "choose4": "Practical Approach",
      "choose4para": "We focus on practical approach to provide hands-on experience.",
      "org": "OUR ORGANIZATION",
      "orgpara": "Quality Education And Practical Knowledge In One Place.",
      "testi": "TESTIMONIALS",
      "testi1": "I have completed my professional training from RSLALPITI and I sincerely appreciate this institution. Their courses are filled with exceptional technical knowledge and high-quality education that has prepared me for the best job opportunities. The diligence and encouragement of the teachers and staff inspire me to work with their interesting style and knowledge.",
      "namet1": "Rajkumar",
      "testi2": "I have completed my professional training from RSLALPITI and I have received the best technical knowledge and education at the highest level from this institution. With the dedication and vigilance of the teachers and staff, this institution prepares its students for their dreams. I have been able to obtain a good job after my training here and I recommend this institution to everyone.",
      "namet2": "Rahul"
    },
    "hi": {
      "admiopen" : "Admission Open",
      "readmore": "और पढ़ें",
      "whoareare": "हम कौन हैं",
      "werhere": "हम यहां 11 साल से हैं",
      "werherecontent": "RSLALPITI (स्वर्गीय आर.एस. लाल औद्योगिक प्रशिक्षण संस्थान) जिले के एक व्यापक रूप से ज्ञात समाज के तहत काम कर रहा है। समाज जिले भर में विभिन्न शैक्षिक कार्यक्रमों और कार्यों में सक्रिय रूप से शामिल है और जिले को एक गणनीय स्थिति में ला रहा है। समाज गर्व से युवाओं के लिए काम करता है और उनके भविष्य के साथ-साथ देश को भी महान बनाने में उनकी मदद करता है।",
      "ychoose": "हमें क्यों चुनें",
      "euneed": "आपको जिस शिक्षा की आवश्यकता है",
      "promise": "आपको सफल बनाने का वादा",
      "choose1": "गुणवत्तापूर्ण शिक्षा",
      "choose1para": "हमारे पास उच्च गुणवत्ता वाले शिक्षा मानक हैं",
      "choose2": "शानदार प्लेसमेंट",
      "choose2para": "हम आपको नियुक्तियों के लिए सहायता प्रदान करेंगे",
      "choose3": "औद्योगिक दौरे",
      "choose3para": "हम औद्योगिक अनुभव देने के लिए औद्योगिक दौरे का आयोजन करते हैं।",
      "choose4": "व्यावहारिक दृष्टिकोण",
      "choose4para": "हम व्यवहारिक अनुभव प्रदान करने के लिए व्यावहारिक दृष्टिकोण पर ध्यान केंद्रित करते हैं।",
      "org": "हमारा संगठन",
      "orgpara": "गुणवत्तापूर्ण शिक्षा और व्यावहारिक ज्ञान एक ही स्थान पर।",
      "testi": "प्रशंसापत्र",
      "testi1": "मैंने RSLALPITI से अपना व्यावसायिक प्रशिक्षण पूरा किया है और मैं इस संस्थान को दिल से सराहना करता हूँ। उनके पाठ्यक्रम उच्च गुणवत्ता के साथ अद्वितीय तकनीकी ज्ञान भरे हुए हैं जो मुझे उच्चतम स्तर के नौकरी के लिए तैयार किया। शिक्षकों और कर्मचारियों की अति सजगता और प्रोत्साहन मुझे उनकी दिलचस्प शैली और ज्ञान के साथ काम करने के लिए प्रेरित करती है।",
      "namet1": "राजकुमार",
      "testi2": "मैंने RSLALPITI से अपना व्यावसायिक प्रशिक्षण पूरा किया है और मुझे इस संस्थान से सबसे अच्छी तकनीकी ज्ञान और उच्चतम स्तर का शिक्षण प्राप्त हुआ है। शिक्षकों और स्टाफ की समर्पण और सजगता के साथ यह संस्थान अपने छात्रों को उनके सपनों के लिए तैयार करता है। मैं यहां से अपने प्रशिक्षण के बाद एक अच्छी नौकरी प्राप्त करने में सक्षम हुआ हूँ और मैं इस संस्थान को हर किसी को सिफारिश करता हूँ।",
      "namet2": "राहुल",
    }
  })
  return (
    <>
    <section className="w3l-content-3">
    <div className="content-3-mian py-5">
      <div className="container py-lg-5">
        <div className="content-info-in row">
          <div className="col-lg-6 border mt-lg-0 mt-5 about-right-faq align-self p-lg-5">
            <div className="title-content text-left mb-2">
              <h6 className="sub-title">Important Notifications</h6>
              <h3 className="hny-title text-danger" id="admiopen">{data[language].admiopen}</h3>
            </div>
            <p className="mt-3">Applications are invited from eligible candidates in the prescribed format for ITC Admission in current session into the following NCVT affiliated trades</p>
            <div className="row">
              <a href="/about" className="col-md btn btn-style btn-primary mt-5 mr-3" style={{fontSize: "12px", padding: "10px 10px"}}>Report Card 2013-15 (Electrician)</a>
              <a href="/about" className="col-md btn btn-style btn-primary mt-5" style={{fontSize: "12px", padding: "10px 10px"}}>Report Card 2013-15 (Electrician)</a>
            </div>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5 about-right-faq align-self  pl-lg-5">
            <div className="title-content text-left mb-2">
              <h6 className="sub-title" id="whoareare">{data[language].whoareare}</h6>
              <h3 className="hny-title" id="werhere">{data[language].werhere}</h3>
            </div>
            <p className="mt-3" id="werherecontent">{data[language].werherecontent}</p>
            <a href="/about" className="btn btn-style btn-primary mt-md-5 mt-4 rdmore">{data[language].readmore}</a>
          </div>
        </div>
      </div>
      </div>
  </section>

  <section className="features-4">
    <div className="features4-block py-5">
      <div className="container py-lg-4">
        <div className="title-content text-center mb-lg-5 mt-4">
          <h6 className="sub-title" id="ychoose">{data[language].ychoose}</h6>
          <h3 className="hny-title" id="euneed">{data[language].euneed}</h3>
          <p className="fea-para" id="promise">{data[language].promise}</p>
        </div>
        <div className="row features4-grids text-left mt-lg-4">
          <div className="col-lg-3 col-md-6 features4-grid mt-4">
            <div className="features4-grid-inn">
              <div className="img-featured">
                <div className="ch-item ch-img-1">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-1"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="features4-rightinfo">
                <h5><a href="#URL" id="choose1">{data[language].choose1}</a></h5>
                <p id="choose1para">{data[language].choose1para}</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 features4-grid mt-4">
            <div className="features4-grid-inn">
              <div className="img-featured">
                <div className="ch-item ch-img-2">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="features4-rightinfo">
                <h5><a href="#URL" id="choose2">{data[language].choose2}
                    </a></h5>
                <p id="choose2para">{data[language].choose2para}</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 features4-grid mt-4">
            <div className="features4-grid-inn">
              <div className="img-featured">
                <div className="ch-item ch-img-3">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-3"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="features4-rightinfo">
                <h5><a href="#URL" id="choose3">{data[language].choose3}
                    </a></h5>
                <p id="choose3para">{data[language].choose3para}</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 features4-grid mt-4">
            <div className="features4-grid-inn">
              <div className="img-featured">
                <div className="ch-item ch-img-4">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="features4-rightinfo">
                <h5><a id="choose4">{data[language].choose4}
                    </a></h5>
                <p id="choose4para">{data[language].choose4para}</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* </div> */}
  </section>

  <section className="w3l-specification-6">
    <div className="specification-6-mian py-5">
      <div className="container py-lg-5">
        <div className="row story-6-grids">
          <div className="col-lg-10 story-gd pl-lg-4  text-center mx-auto">
            <div className="title-content px-lg-5">
              <h6 className="sub-title" id="org">{data[language].org}</h6>
              <h3 className="hny-title two" id="orgpara">{data[language].orgpara}</h3>
            </div>
            <div className="skill_info mt-5 pt-lg-4">
              <div className="stats_left">
                <div className="counter_grid">
                  <div className="icon_info">
                    <p className="counter">240</p>
                    <h4>Students</h4>

                  </div>
                </div>
              </div>
              <div className="stats_left">
                <div className="counter_grid">
                  <div className="icon_info">
                    <p className="counter">2</p>
                    <h4>Courses</h4>

                  </div>
                </div>
              </div>
              <div className="stats_left">
                <div className="counter_grid">
                  <div className="icon_info">
                    <p className="counter">12</p>
                    <h4>Faculties</h4>

                  </div>
                </div>
              </div>
              <div className="stats_left">
                <div className="counter_grid">
                  <div className="icon_info">
                    <p className="counter">3</p>
                    <h4>Labs</h4>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>

  <section className="w3l-testimonials">
    <div className="testimonials py-5">
      <div className="container text-center py-lg-3">
        <div className="title-content text-center mb-lg-5 mb-4">
          <h6 className="sub-title" id="testi">{data[language].testi}</h6>
        </div>
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <Testimonials />
          </div>
        </div>
      </div>
    </div>
  </section>
  
    </>
  )
}

export default Home
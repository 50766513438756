import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({onLanguageChange}) => {
  return (
    <header id="site-header" className="fixed-top">
  <div className="container">
    <nav className="navbar navbar-expand-lg navbar-light">
      <h1>
        <a className="navbar-brand" href="index.html">
          <img className="img-fluid" src="./assets/images/rslogo2.png" style={{maxWidth: "190px"}}/>
        </a>
      </h1>
      <button className="navbar-toggler collapsed" id="closer" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="fa icon-expand fa-bars"></span>
        <span className="fa icon-close fa-times"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" onClick={()=>{
              document.getElementById('closer').click()
            }} to="/">Home </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" onClick={()=>{
              document.getElementById('closer').click()
            }} to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" onClick={()=>{
              document.getElementById('closer').click()
            }} to="/courses">Courses</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" onClick={()=>{
              document.getElementById('closer').click()
            }} to="/gallery">Gallery</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" onClick={()=>{
              document.getElementById('closer').click()
            }} to="/faculties">Faculties</Link>
          </li>
        </ul>
        <ul className="navbar-nav search-right mt-lg-0 mt-2">
          <li className="nav-item">
            <Link to="/contact" onClick={()=>{
              document.getElementById('closer').click()
            }} className="btn btn-primary d-lg-block btn-style mr-2">Contact
              Us</Link>
          </li>
        </ul>

      </div>
      <div className="mobile-position">
        <nav className="navigation">
          <div className="theme-switch-wrapper">
            <li className="nav-item">
              <select id="lng" onChange={onLanguageChange} style={{padding: "5px 10px",border: "2px solid #238fbd",borderRadius: "0px",color: "#238fbd"}}>
                <option value="en">ENG</option>
                <option value="hi" selected>हिंदी</option>
              </select>
            </li>
          </div>
        </nav>
      </div>
    </nav>
  </div>
</header>
  )
}

export default Header
import React, { useContext, useEffect, useState } from 'react'
import {LanguageContext} from '../App'

const Gallery = () => {
    useEffect(()=>{
        window.scroll(0,0)
      },[])
    const {language} = useContext(LanguageContext);
    const [data,setData] = useState({
        "en": {
            "title": "Gallery",
            "description": "Our Events"
        },
        "hi": {
            "title": "गेलरी",
            "description": "हमारे कार्यक्रम"
        }
    })
  return (
    <section className="w3l-team-main py-5" id="team">
        <div className="container py-lg-4">
            <div className="title-content text-center mb-lg-5 mb-4">
                <h6 className="sub-title" id="title">{data[language].title}</h6>
                <h3 className="hny-title" id="description">{data[language].description}</h3>
            </div>
            <div className="row inner-sec-w3layouts-w3pvt-lauinfo mt-3">
                <div className="col-md-4 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/g1.jpg" className="img-fluid mb-4" style={{height: "275px",
    objectFit: "cover"}} alt=""/>
                    </div>
                </div>
                <div className="col-md-4 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/g2.jpg" className="img-fluid mb-4" style={{height: "275px",
    objectFit: "cover"}} alt=""/>
                    </div>
                </div>
                <div className="col-md-4 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/g3.jpg" className="img-fluid mb-4" style={{height: "275px",
    objectFit: "cover"}} alt=""/>
                    </div>
                </div>
                <div className="col-md-4 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/g4.jpg" className="img-fluid mb-4" style={{height: "275px",
    objectFit: "cover"}} alt=""/>
                    </div>
                </div>
                <div className="col-md-4 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/g5.jpg" className="img-fluid mb-4" style={{height: "275px",
    objectFit: "cover"}} alt=""/>
                    </div>
                </div>
                <div className="col-md-4 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/g6.jpg" className="img-fluid mb-4" style={{height: "275px",
    objectFit: "cover"}} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>

  )
}

export default Gallery
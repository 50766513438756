import { Route,Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Courses from "./components/Courses";
import Faculties from "./components/Faculties";
import Gallery from "./components/Gallery";
import Home from "./components/Home";

const Routing = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/courses" element={<Courses/>} />
            <Route exact path="/faculties" element={<Faculties />} />
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/gallery" element={<Gallery/>} />
        </Routes>
    )
}

export default Routing;
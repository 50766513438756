import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="w3l-footer-66">
  <section className="footer-inner-main">
    <div className="footer-hny-grids py-5">
      <div className="container py-lg-4">
        <div className="text-txt">
          <div className="">
            <div className="row sub-columns">
              <div className="col-lg-4 col-md-6 sub-one-left pr-lg-4">
                <h2><a className="navbar-brand" href="index.html">
                    <img className="img-fluid" style={{maxWidth:"60%",marginBottom:"20px"}} src="./assets/images/rslogo2.png" />
                  </a></h2>
                <p className="pr-lg-4" id="ftitle">RSLALPITI is working as an active body of a widely known society of the district Deoria and is dedicated to provide quality education to the youth and bring the district into the map.</p>
                <div className="columns-2">
                  <ul className="social">
                    <li><a href="https://www.facebook.com/rslal.iti"><span className="fa fa-facebook" aria-hidden="true"></span></a>
                    </li>
                    <li><a href="https://www.youtube.com/@later.s.lalpvti.t.i.7794"><span className="fa fa-youtube" aria-hidden="true"></span></a>
                    </li>
                    {/* <!-- <li><a href="#github"><span className="fa fa-github" aria-hidden="true"></span></a>
                      </li> --> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg col-md-6 sub-one-left">
                <h6 id="fql">Quick Links</h6>
                <div className="mid-footer-gd sub-two-right">
                  <ul>
                    <li><Link to="/about"><span className="fa fa-angle-double-right mr-2"></span> About</Link>
                    </li>
                    <li><Link to="/courses"><span className="fa fa-angle-double-right mr-2"></span> Courses</Link>
                    </li>
                    <li><Link to="/faculties"><span className="fa fa-angle-double-right mr-2"></span> Faculties</Link>
                    </li>
                    <li><Link to="/contact"><span className="fa fa-angle-double-right mr-2"></span>Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg col-md-6 sub-one-left">
                <h6 id="fsl">Social Links</h6>
                <div className="mid-footer-gd sub-two-right">
                  <ul>
                    <li><a href="https://www.facebook.com/rslal.iti"><span className="fa fa-angle-double-right mr-2"></span> Facebook</a>
                    </li>
                    <li><a href="https://www.youtube.com/@later.s.lalpvti.t.i.7794"><span className="fa fa-angle-double-right mr-2"></span> Youtube</a>
                    </li>
                    <li><a href="https://wa.me/919119601313"><span className="fa fa-angle-double-right mr-2"></span>Whatsapp</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 sub-one-left">
                <h6 id="fci">Contact Info</h6>
                <div className="sub-contact-info">
                  <p id="fadress">Address: Pokharbhinda, Hata RoadDeoria (Uttar Pradesh) - 274001</p>
                  <p className="my-3" id="fphone">Phone: <strong><a href="tel:05568-297148">05568-297148</a></strong></p>
                  <p>E-mail:<strong> <a href="mailto:rslalpiti2411@gmail.com">rslalpiti2411@gmail.com</a></strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="below-section">
      <div className="container">
        <div className="copyright-footer">
          <div className="columns text-lg-left">
            <p>© 2023 RSLALPITI. All rights reserved | Designed by <a href="https://dziretechnologies.com">Dzire Infotech Pvt Ltd</a></p>
          </div>
          <ul className="columns text-lg-right">
            <li><a href="#">Privacy Policy</a>
            </li>
            <li>|</li>
            <li><a href="#">Terms Of Use</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <button onclick="topFunction()" id="movetop" title="Go to top">
      <span className="fa fa-long-arrow-up" aria-hidden="true"></span>
    </button>
    {/* <script>
      window.onscroll = function() {
        scrollFunction()
      };

      function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          document.getElementById("movetop").style.display = "block";
        } else {
          document.getElementById("movetop").style.display = "none";
        }
      }

      // When the user clicks on the button, scroll to the top of the document
      function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    </script> */}

  </section>
</footer>
  )
}

export default Footer
import React, { useContext, useEffect, useState } from 'react'
import {LanguageContext} from '../App'

const About = () => {
  useEffect(()=>{
  window.scroll(0,0)
},[])
  const {language} = useContext(LanguageContext)
  const [data,setData] = useState({
    "en": {
      "readmore" : "Read More",
      "title": "About us",
      "description": "We are qualified & experience in this field",
      "content": "RSLALPITI (Late R.S. Lal Private Industrial Training Institute) is a Self-financing training institute established in the year 2012 and registered under Society Registration Act. The institute is dedicated for the betterment of the youth by providing them quality technical training in various fields.",
      "whower": "Who We Are",
      "qit": "Quality Industrial Training",
      "qitc": "RSLALPITI (Late R.S. Lal Industrial Training Institute) is working under a widely known society of the district. The society is actively involved in various educational events and functions across the district and bringing the district to a countable state. The society is proudly works for the youth and help them make their future as well as the nation great.",
      "highlights": "Hightlights",
      "highlightssubject": "We have Technical Labs to provide practical approach to our students.",
      "highlightscontent": "The college has developed excellent facilities and resources such as well-equipped laboratories, well-stacked libraries, dedicated Computer Center, Spacious Class rooms, Seminar Halls and experienced faculty members. It strives to achieve global identity through its innovative methods and strenuous efforts to promote Engineering Education for the betterment of the student's community."
    },
    "hi": {
      "readmore" : "और पढ़ें",
      "title": "हमारे बारे में",
      "description": "हम इस क्षेत्र में योग्य और अनुभवी हैं",
      "content": "RSLALPITI(लेट आर.एस. लाल निजी औद्योगिक प्रशिक्षण संस्थान) एक स्वायत्त वित्त पोषित प्रशिक्षण संस्थान है जो 2012 में स्थापित किया गया था और सोसाइटी रजिस्ट्रेशन एक्ट के तहत पंजीकृत है। संस्थान विभिन्न क्षेत्रों में गुणवत्ता वाले तकनीकी प्रशिक्षण प्रदान करके युवाओं की सुधार के लिए समर्पित है।",
      "whower": "हम कौन हैं",
      "qit": "गुणवत्ता वाला औद्योगिक प्रशिक्षण",
      "qitc": "RSLALPITI(लेट आर.एस. लाल औद्योगिक प्रशिक्षण संस्थान) जिले की एक विख्यात सोसाइटी के तहत कार्यरत है। सोसाइटी जिले में विभिन्न शैक्षिक घटनाओं और कार्यक्रमों में सक्रिय रूप से शामिल है और जिले को एक गिनतीय स्थिति तक पहुंचाने में मदद कर रही है। सोसाइटी गर्व से युवाओं के लिए काम करती है और उन्हें उनके भविष्य की मदद करती है जो उन्हें उनके राष्ट्र को महान बनाने में मदद करते हैं।",
      "highlights": "अंक उठाने वाली बातें",
      "highlightssubject": "हमारे पास तकनीकी प्रयोगशालाएं हैं जो हमारे छात्रों को व्यावहारिक दृष्टिकोण प्रदान करने में मदद करती हैं।",
      "highlightscontent": "कॉलेज ने उत्कृष्ट सुविधाओं और संसाधनों को विकसित किया है जैसे सुसज्जित प्रयोगशालाएं, अच्छी तरह से भरी हुई लाइब्रेरी, विशेष कम्प्यूटर केंद्र, विस्तृत कक्षाएं, सेमिनार हॉल और अनुभवी शिक्षक सदस्य। इसका लक्ष्य छात्र समुदाय के सुधार के लिए इंजीनियरिंग शिक्षा को बढ़ावा देने के लिए नवाचारी तरीकों और कठोर प्रयासों के माध्यम से वैश्विक पहचान हासिल करना है।"
    }
  })
  return (
    <>
    <section className="w3l-content-6">
    <div className="content-6-mian py-5">
      <div className="container py-lg-5">
        <div className="content-info-in row">
          <div className="col-lg-6">
            <img src="assets/images/ab7.jpg" alt="" className="img-fluid"/>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5 about-right-faq align-self  pl-lg-4">
            <div className="title-content text-left mb-2">
              <h6 className="sub-title" id="title">{data[language].title}</h6>
              <h3 className="hny-title" id="description">{data[language].description}</h3>
            </div>
            <p className="mt-3" id="content">{data[language].content}</p>
            <a href="/about" className="btn btn-style btn-primary mt-4 rdmore">{data[language].readmore}</a>
          </div>
          <div className="col-lg-6 mt-5 about-right-faq align-self order2">
            <div className="title-content text-left mb-2">
              <h6 className="sub-title" id="whower">{data[language].whower}</h6>
              <h3 className="hny-title" id="qit">{data[language].qit}</h3>
            </div>
            <p className="mt-3" id="qitc">{data[language].qitc}</p>
            <a href="/about" className="btn btn-style btn-primary mt-4 rdmore">{data[language].readmore}</a>
          </div>
          <div className="col-lg-6 mt-5 pl-lg-4">
            <img src="assets/images/ab8.jpg" alt="" className="img-fluid"/>
          </div>


        </div>
      </div>
      </div>
  </section>
  <section className="w3l-content-4">
    <div className="content-4-main py-5">
      <div className="container py-lg-4">
        <div className="content-info-in row">

          <div className="content-right col-lg-6">
            <img src="assets/images/ab9.jpg" className="img-fluid" alt=""/>
          </div>
          <div className="content-left col-lg-6 pl-lg-5">
            <h6 className="sub-title" id="highlights">{data[language].highlights}
              </h6>
            <h3 className="hny-title" id="highlightssubject">{data[language].highlightssubject}
             
            </h3>
            <p className="mt-3" id="highlightscontent">{data[language].highlightscontent} </p>

          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default About
import logo from './logo.svg';
import './App.css';
import Routing from './Routing';
import Header from './components/Header';
import Slider from './components/Slider';
import Footer from './components/Footer';
import {createContext, useState} from 'react'
export const LanguageContext = createContext()

function App() {
  const [language,setLanguage] = useState('hi')
  return (
    <>
    <Header onLanguageChange={(e) => {
      setLanguage(e.target.value)
    }}/>
      <LanguageContext.Provider value={{language}} >
    <Slider />
      <Routing />
      </LanguageContext.Provider>
      <Footer />
      </>
  );
}

export default App;

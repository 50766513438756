import React, { useContext, useEffect, useState } from 'react'
import {LanguageContext} from '../App'

const Contact = () => {
	useEffect(()=>{
		window.scroll(0,0)
	  },[])
	const {language} = useContext(LanguageContext);
	const [data,setData] = useState({
		"en": {
			"title": "Contact Us",
			"subject": "Get In Touch",
			"description": "Hi there, We are available 24/7 by e-mail or by phone. Drop us line so we can talk futher about that.",
			"greeting": "For more info or inquiry about our courses and admission please feel free to get in touch with us.",
			"email": "Email:",
			"address": "Address:",
			"advalue": "Pokharbhinda, Hata RoadDeoria (Uttar Pradesh) - 274001",
			"contact": "Contact:",
			"sendtitle": "Send Us A Message",
			"send": "Send"
		},
		"hi": {
			"title": "हमसे संपर्क करें",
			"subject": "संपर्क में रहें",
			"description": "नमस्ते, हम ईमेल या फोन द्वारा 24/7 उपलब्ध हैं। कृपया हमसे संपर्क करें ताकि हम इस बारे में और अधिक चर्चा कर सकें।",
			"greeting": "हमारे कोर्स और एडमिशन के बारे में अधिक जानकारी या कुछ पूछना हो तो कृपया हमसे संपर्क करें।",
			"email": "ईमेल:",
			"address": "पता:",
			"advalue": "पोखरभिंडा, हाटा रोड, देवरिया (उत्तर प्रदेश) - 274001",
			"contact": "संपर्क:",
			"sendtitle": "हमें एक संदेश भेजें",
			"send": "भेजें"
		}
	})
  return (
    <section className="w3l-contact-11">
		<div className="form-41-mian py-5">
			<div className="container py-lg-4">
				<div className="row align-form-map">
					<div className="col-lg-6 contact-left pr-lg-4">
						<div className="partners">
							<div className="cont-details">
								<div className="title-content text-left">
									<h6 className="sub-title" id="title">{data[language].title}</h6>
									<h3 className="hny-title" id="subject">{data[language].subject}</h3>
								</div>
								<p className="mt-3 mb-4 pr-lg-5" id="description">{data[language].description}</p>
								<h6 className="mb-4" id="greeting">{data[language].greeting}</h6>
							</div>
							<div className="hours">
								<h6 className="mt-4" id="email">{data[language].email}</h6>
								<p> <a href="mailto:rslalpiti2411@gmail.com">
										rslalpiti2411@gmail.com</a></p>
								<h6 className="mt-4" id="address">{data[language].address}</h6>
								<p id="advalue">{data[language].advalue} </p>
								<h6 className="mt-4" id="contact">{data[language].contact}</h6>
								<p className="margin-top"><a href="tel:+919119601313">+91-9119601313</a>
								</p>
								<p><a href="tel:+918840554550">+91-8840554550</a></p>
								
								<p><a href="tel:05568297148">05568-297148</a></p>
							</div>
						</div>
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3567.3975049128717!2d83.81333904991834!3d26.603702479648156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3993c3baaaaaaaab%3A0x5fbec18296201387!2sR%20S%20LAL%20PRIVATE%20ITI!5e0!3m2!1sen!2sin!4v1679016114079!5m2!1sen!2sin" width="100%" height="auto" style={{border:0,minHeight:"300px",marginTop:"50px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
					<div className="col-lg-6 form-inner-cont">
						<div className="title-content text-left">
							<h3 className="hny-title mb-lg-5 mb-4" id="sendtitle">{data[language].sendtitle}</h3>
						</div>
						<form action="sendmail.php" method="post" className="signin-form">
							<div className="form-input">
								<input type="text" name="name" id="w3lName" placeholder="Name" />
							</div>
							<div className="row con-two">
								<div className="col-lg-6 form-input">
									<input type="email" name="email" id="w3lSender" placeholder="Email" required="" />
								</div>
								<div className="col-lg-6 form-input">
									<input type="text" name="subject" placeholder="Subject" className="contact-input" />
								</div>
							</div>
							<div className="form-input">
								<textarea placeholder="Message" name="message" id="w3lMessage" required=""></textarea>
							</div>
							<div className="submit-button text-lg-right">
								<button type="submit" className="btn btn-style" id="send">{data[language].send}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Contact
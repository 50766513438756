import React, { useContext, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { LanguageContext } from '../App';

const Testimonials = () => {
  const {language} = useContext(LanguageContext)
  const [data,setData] = useState({
    "en" : {
      "testi1": "I have completed my professional training from RSLALPITI and I sincerely appreciate this institution. Their courses are filled with exceptional technical knowledge and high-quality education that has prepared me for the best job opportunities. The diligence and encouragement of the teachers and staff inspire me to work with their interesting style and knowledge.",
        "namet1": "Rajkumar",
        "testi2": "I have completed my professional training from RSLALPITI and I have received the best technical knowledge and education at the highest level from this institution. With the dedication and vigilance of the teachers and staff, this institution prepares its students for their dreams. I have been able to obtain a good job after my training here and I recommend this institution to everyone.",
        "namet2": "Rahul"
    },
    "hi" : {
      "testi1": "मैंने RSLALPITI से अपना व्यावसायिक प्रशिक्षण पूरा किया है और मैं इस संस्थान को दिल से सराहना करता हूँ। उनके पाठ्यक्रम उच्च गुणवत्ता के साथ अद्वितीय तकनीकी ज्ञान भरे हुए हैं जो मुझे उच्चतम स्तर के नौकरी के लिए तैयार किया। शिक्षकों और कर्मचारियों की अति सजगता और प्रोत्साहन मुझे उनकी दिलचस्प शैली और ज्ञान के साथ काम करने के लिए प्रेरित करती है।",
        "namet1": "राजकुमार",
        "testi2": "मैंने RSLALPITI से अपना व्यावसायिक प्रशिक्षण पूरा किया है और मुझे इस संस्थान से सबसे अच्छी तकनीकी ज्ञान और उच्चतम स्तर का शिक्षण प्राप्त हुआ है। शिक्षकों और स्टाफ की समर्पण और सजगता के साथ यह संस्थान अपने छात्रों को उनके सपनों के लिए तैयार करता है। मैं यहां से अपने प्रशिक्षण के बाद एक अच्छी नौकरी प्राप्त करने में सक्षम हुआ हूँ और मैं इस संस्थान को हर किसी को सिफारिश करता हूँ।",
        "namet2": "राहुल",
    }
  })
  return (
    <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Pagination, Autoplay]}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          onSlideChange={() => { }}
          onSwiper={(swiper) => { }}
        >
          <SwiperSlide className='swiper-slide'>
          <div className="slider-info mt-lg-4 mt-3">
                  <div className="img-circle">
                    <img src="assets/images/team1.jpg" className="img-fluid rounded" alt="client image"/>
                  </div>
                  <div className="message" id="testi1">{data[language].testi1}</div>
                  <div className="name" id="namet1">- {data[language].namet1}</div>

                </div>
          </SwiperSlide>
          {/* <div className="item"> */}
          <SwiperSlide className='swiper-slide'>
          <div className="slider-info mt-lg-4 mt-3">
                  <div className="img-circle">
                    <img src="assets/images/team2.jpg" className="img-fluid rounded" alt="client image"/>
                  </div>
                  <div className="message" id="testi2">{data[language].testi2}</div>
                  <div className="name" id="namet2">- {data[language].namet2}</div>
                </div>
          </SwiperSlide>
          </Swiper>
  )
}

export default Testimonials
import React, { useContext, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {LanguageContext} from '../App'

const Slider = () => {
  const {language} = useContext(LanguageContext);
  const [data,setData] = useState({
    "en" : {
      "banner1": "Best ITI College in the region",
      "banner2": "Qualified Faculties",
      "banner3": "Knowledge driven education",
      "banner4": "Placement Assistance",
      "banner5": "Cultural Events",
      "banner6": "Personality Development",
      "readmore" : "Read More"
    },
    "hi" : {
      "banner1": "क्षेत्र का सर्वश्रेष्ठ आईटीआई कॉलेज",
      "banner2": "योग्य संकाय",
      "banner3": "ज्ञान संचालित शिक्षा",
      "banner4": "प्लेसमेंट सहायता",
      "banner5": "सांस्कृतिक कार्यक्रम",
      "banner6": "व्यक्तित्व विकास",
      "readmore" : "और पढ़ें"
    }
  })
  return (
    <section className="w3l-main-slider position-relative" id="home">
      <div className="companies20-content">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          onSlideChange={() => { }}
          onSwiper={(swiper) => { }}
        >
          <SwiperSlide className='swiper-slide'>
            <li>
              <div className="slider-info banner-view bg bg2">
                <div className="banner-info">
                  <div className="container">
                    <div className="banner-info-bg">
                      <h5 id="banner1">{data[language].banner1}</h5>
                      <div className="banner-buttons">
                        <a className="btn btn-style btn-primary rdmore" href="/about">{data[language].readmore}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </SwiperSlide>
          {/* <div className="item"> */}
          <SwiperSlide className='swiper-slide'>
            <li>
              <div className="slider-info banner-view banner-top2 bg bg2">
                <div className="banner-info">
                  <div className="container">
                    <div className="banner-info-bg">
                      <h5 id="banner3">{data[language].banner3}</h5>
                      <div className="banner-buttons">
                        <a className="btn btn-style btn-primary rdmore" href="/about">{data[language].readmore}</a>
                        <div id="small-dialog2" className="zoom-anim-dialog mfp-hide">
                          <iframe src="https://player.vimeo.com/video/425349644" allow="autoplay; fullscreen" allowfullscreen=""></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </SwiperSlide>
          <SwiperSlide className='swiper-slide'>
            <li>
              <div className="slider-info banner-view banner-top3 bg bg2">
                <div className="banner-info">
                  <div className="container">
                    <div className="banner-info-bg">
                      <h5 id="banner4">{data[language].banner4}</h5>
                      <div className="banner-buttons">
                        <a className="btn btn-style btn-primary rdmore" href="/about">{data[language].readmore}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </SwiperSlide>
          <SwiperSlide className='swiper-slide'>
            <li>
              <div className="slider-info banner-view banner-top4 bg bg2">
                <div className="banner-info">
                  <div className="container">
                    <div className="banner-info-bg">
                      <h5 id="banner5">{data[language].banner5}</h5>
                      <div className="banner-buttons">
                        <a className="btn btn-style btn-primary rdmore" href="/about">{data[language].readmore}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </SwiperSlide>
          <SwiperSlide className='swiper-slide'>
            <li>
              <div className="slider-info banner-view banner-top5 bg bg2">
                <div className="banner-info">
                  <div className="container">
                    <div className="banner-info-bg">
                      <h5 id="banner6">{data[language].banner6}</h5>
                      <div className="banner-buttons">
                        <a className="btn btn-style btn-primary rdmore" href="/about">{data[language].readmore}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </SwiperSlide>
          <SwiperSlide className='swiper-slide'>
            <li>
              <div className="slider-info  banner-view banner-top1 bg bg2">
                <div className="banner-info">
                  <div className="container">
                    <div className="banner-info-bg">
                      <h5 id="banner2">{data[language].banner2}</h5>
                      <div className="banner-buttons">
                        <a className="btn btn-style btn-primary rdmore" href="/about">{data[language].readmore}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </SwiperSlide>
          </Swiper>
      </div>
    </section>
  )
}

export default Slider
import React, { useContext, useEffect, useState } from 'react'
import {LanguageContext} from '../App'

const Faculties = () => {
    useEffect(()=>{
        window.scroll(0,0)
      },[])
    const {language} = useContext(LanguageContext)
    const [data,setData] = useState({
        "en": {
            "title": "Our Faculties",
            "description": "Meet Our Team",
            "name1": "Sugreev Prasad Sharma",
            "des1": "Senior Faculty (Fitter)",
            "exp1": "Experience : 36 Years",
            "edu1": "Senior Faculty (Fitter)",
            "name2": "Priyanka Singh",
            "des2": "Senior Faculty (Fitter)",
            "exp2": "Experience : 7 Years",
            "edu2": "ITI (Fitter) + CTI",
            "name3": "Shikha Mall",
            "des3": "Senior Faculty (Electrician)",
            "exp3": "Experience : 7 Years",
            "edu3": "ITI (Electrician) + CTI",
            "name4": "P.P Sharma",
            "des4": "Senior Faculty (Electrician)",
            "exp4": "Experience : 40 Years",
            "edu4": "ITI (Electrician)",
            "name5": "Avnindra Kumar Srivastava",
            "des5": "Director",
            "exp5": "Experience : 10 Years",
            "edu5": "Director",
            "name6": "Manish Kumar Srivastava",
            "des6": "Manager",
            "exp6": "Experience : 12 Years",
            "edu6": "Manager",
            "name7": "M. P. Srivastava",
            "des7": "Principal",
            "exp7": "Experience : 30 Years",
            "edu7": "Principal",
        },
        "hi": {
            "title": "हमारे अध्यापक",
            "description": "हमारी टीम से मिलें",
            "name1": "सुग्रीव प्रसाद शर्मा",
            "des1": "वरिष्ठ अध्यापक (फिटर)",
            "exp1": "अनुभव: 36 वर्ष",
            "edu1": "वरिष्ठ अध्यापक (फिटर)",
            "name2": "प्रियंका सिंह",
            "des2": "वरिष्ठ अध्यापक (फिटर)",
            "exp2": "अनुभव: 7 वर्ष",
            "edu2": "ITI (फिटर) + CTI",
            "name3": "शिखा मल्ल",
            "des3": "वरिष्ठ अध्यापक (इलेक्ट्रीशियन)",
            "exp3": "अनुभव: 7 वर्ष",
            "edu3": "ITI (इलेक्ट्रीशियन) + CTI",
            "name4": "पी.पी. शर्मा",
            "des4": "वरिष्ठ अध्यापक (इलेक्ट्रीशियन)",
            "exp4": "अनुभव: 40 वर्ष",
            "edu4": "ITI (इलेक्ट्रीशियन)",
            "name5": "अवनींद्र कुमार श्रीवास्तव",
            "des5": "निदेशक",
            "exp5": "अनुभव: 12 वर्ष",
            "edu5": "निदेशक",
            "name6": "मनीष कुमार श्रीवास्तव",
            "des6": "प्रबंधक",
            "exp6": "अनुभव: 12 वर्ष",
            "edu6": "प्रबंधक",
            "name7": "एम.पी. श्रीवास्तव",
            "des7": "प्राचार्य",
            "exp7": "अनुभव: 30 वर्ष",
            "edu7": "प्राचार्य"
        }
    })
  return (
    <section className="w3l-team-main py-5">
        <div className="container py-lg-4">
            <div className="title-content text-center mb-lg-5 mb-4">
                <h6 className="sub-title" id="title">{data[language].title}</h6>
                <h3 className="hny-title" id="description">{data[language].description}</h3>
            </div>
            <div className="row inner-sec-w3layouts-w3pvt-lauinfo justify-content-center mt-3">
                <div className="col-md-3 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/t5.jpg" className="img-fluid" alt=""/>
                        <div className="team-info">
                            <div className="caption">
                                <h4 id="name5">{data[language].name5}</h4>
                                <h6 id="des5">{data[language].des5}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="social-icons-section">
                        <h6 id="edu5">{data[language].edu5}</h6>
                    </div>
                </div>
            </div>
            <div className="row inner-sec-w3layouts-w3pvt-lauinfo justify-content-center mt-5">
                <div className="col-md-3 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/t6.jpg" className="img-fluid" alt=""/>
                        <div className="team-info">
                            <div className="caption">
                                <h4 id="name6">{data[language].name6}</h4>
                                <h6 id="des6">{data[language].des6}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="social-icons-section">
                        <h6 id="exp6">{data[language].exp6}</h6>
                        <h6 id="edu6">{data[language].edu6}</h6>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/t7.jpg" className="img-fluid" alt=""/>
                        <div className="team-info">
                            <div className="caption">
                                <h4 id="name7">{data[language].name7}</h4>
                                <h6 id="des7">{data[language].des7}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="social-icons-section">
                        <h6 id="exp7">{data[language].exp7}</h6>
                        <h6 id="edu7">{data[language].edu7}</h6>
                    </div>
                </div>

            </div>
            <div className="row inner-sec-w3layouts-w3pvt-lauinfo  mt-5">
                <div className="col-md-3 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/t1.jpg" className="img-fluid" alt=""/>
                        <div className="team-info">
                            <div className="caption">
                                <h4 id="name1">{data[language].name1}</h4>
                                <h6 id="des1">{data[language].des1}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="social-icons-section">
                        <h6 id="exp1">{data[language].exp1}</h6>
                        <h6 id="edu1">{data[language].edu1}</h6>
                    </div>
                </div>
                <div className="col-md-3 col-6">
                    <div className="team-grids text-center">
                        <img src="assets/images/t2.jpg" className="img-fluid" alt=""/>
                        <div className="team-info">
                            <div className="caption">
                                <h4 id="name2">{data[language].name2}</h4>
                                <h6 id="des2">{data[language].des2}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="social-icons-section">
                        <h6 id="exp2">{data[language].exp2}</h6>
                        <h6 id="edu2">{data[language].edu2}</h6>
                    </div>
                </div>
                <div className="col-md-3 col-6 mt-md-0 mt-4">
                    <div className="team-grids text-center">
                        <img src="assets/images/t3.jpg" className="img-fluid" alt=""/>
                        <div className="team-info">
                            <div className="caption">
                                <h4 id="name3">{data[language].name3}</h4>
                                <h6 id="des3">{data[language].des3}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="social-icons-section">
                        <h6 id="exp3">{data[language].exp3}</h6>
                        <h6 id="edu3">{data[language].edu3}</h6>
                    </div>
                </div>
                <div className="col-md-3 col-6 mt-md-0 mt-4">
                    <div className="team-grids text-center">
                        <img src="assets/images/t4.jpg" className="img-fluid" alt=""/>
                        <div className="team-info">
                            <div className="caption">
                                <h4 id="name4">{data[language].name4}</h4>
                                <h6 id="des4">{data[language].des4}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="social-icons-section">
                        <h6 id="exp4">{data[language].exp4}</h6>
                        <h6 id="edu4">{data[language].edu4}</h6>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Faculties